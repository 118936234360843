import {reactive, unref} from 'vue'

import {store} from './Store';
import {mdservices} from './MarketData';


export const URL_SUFFIX = ''; //'.json';

// TO DO:
// - add accessors for all the Alice API
// - create a mapping table to map Alice instruments to LMAX
//    - apply this invisibly to price subscriptions and chart fetches
// - implement the logon flow incl SIWE
// - initiate a real trade onto our network/chain
// - implement the trade flow incl connect/parallel stage
// - implement the trade pending stages and UI
// - make the history panel actually work incl the refund button


export function getAPIurlPrefix()
{
    return store.apiUrl;
}

export const urls = reactive({
    coinListUrl: () => '/crypto-icons/manifest.json',
    infoUrl: () => getAPIurlPrefix() + '/info' + URL_SUFFIX,
    historyUrl: (orderSource) => getAPIurlPrefix() + '/protected/' + orderSource + '/orderHistory' + URL_SUFFIX,
    positionsUrl: (orderSource) => getAPIurlPrefix() + '/protected/' + orderSource + '/openPositions' + URL_SUFFIX,
});

export const loaders = reactive({
    async loadCoins()
    {
        store.tokenStatus = 'Loading...';
        try
        {
            const res = await fetch(urls.coinListUrl());
            store.tokenList = (await res.json());
            store.tokenStatus = 'Ready; ' + store.tokenList.length + ' coins loaded';
            let count = 0;
            for (let coin of store.tokenList)
            {
                coin.ID = count++;
                store.tokenDict[coin.ID] = coin;
                store.tokenIDs[coin.symbol] = coin;
            }
        }
        catch (error)
        {
            store.tokenStatus = 'Error! Could not reach the API ' + error
        }
    },
    injectToken(token)
    {
        if (!(token in store.tokenIDs))
        {
            let count = store.tokenList.length;
            let coin = {
                "ID": count++,
                "symbol": token,
                "name": token,
                "color": "#000000"
            }
            store.tokenDict[coin.ID] = coin;
            store.tokenIDs[coin.symbol] = coin;
            store.tokenList.push(coin);
        }
    },
    injectCoins()
    {
        for (let instrument of store.instrumentList)
        {
            this.injectToken(instrument.baseSymbol, instrument);
            this.injectToken(instrument.quoteSymbol, instrument);
        }
    },
    async loadInstruments()
    {
        if (store.tokenList.length == 0)
        {
            await this.loadCoins();
        }
        store.instrumentStatus = 'Loading...';
        try
        {
            if (store.apiUrl)
            {
                const res = await fetch(urls.infoUrl());
                store.backendInfo = (await res.json()).info;
                const chainInfo = store.getChainInfo();
                store.instrumentList = unref(chainInfo.instruments).filter(instrument => instrument.baseSymbol.toUpperCase() != "XAU" && instrument.quoteSymbol.toUpperCase() != "XAU");
                this.injectCoins();
                try
                {
                    let ethscanPrefix = unref(chainInfo.txExplorerUrl);
                    if (ethscanPrefix)
                    {
                        store.ethscanPrefix = ethscanPrefix;
                    }
                    store.domainForSIWE = unref((store.backendInfo as any).domain);
                }
                catch (e)
                {
                    console.log('ERROR, failed to load all info from /info endpoint', e);
                    store.instrumentStatus = 'Error...';
                }
                console.log('ethscanPrefix', store.ethscanPrefix);
                console.log('domainForSIWE', store.domainForSIWE);
                //store.instrumentStatus = 'Ready; '+store.instrumentList.length+' instruments loaded';
                console.log('instrumentList', store.instrumentStatus, store.instrumentList);
                console.log(store.instrumentStatus);
                if (store.instrumentList.length > 0)
                {
                    for (let instrument of store.instrumentList)
                    {
                        if (instrument.maxLeverage > 1)
                        {
                            if (instrument.baseSymbol == "btc") {
                                instrument.baseSymbol = "wBTC";
                            } else if (instrument.baseSymbol == "eth") {
                                instrument.baseSymbol = "ETH";
                            }
                            store.instrumentDict["lev_" + instrument.instrumentId] = instrument;
                            store.instrumentPairs["lev_" + instrument.baseSymbol + '/' + instrument.quoteSymbol] = {
                                id: instrument.instrumentId,
                                inverted: false
                            };
                            store.instrumentPairs["lev_" + instrument.quoteSymbol + '/' + instrument.baseSymbol] = {
                                id: instrument.instrumentId,
                                inverted: true
                            };
                        } else {
                            store.instrumentDict[instrument.instrumentId] = instrument;
                            store.tokenAddresses[instrument.baseSymbol] = instrument.baseContractAddress;
                            store.tokenAddresses[instrument.quoteSymbol] = instrument.quoteContractAddress;
                            store.tokenDPs[instrument.baseSymbol] = instrument.baseDecimals;
                            store.tokenDPs[instrument.quoteSymbol] = instrument.quoteDecimals;
                            store.instrumentPairs[instrument.baseSymbol + '/' + instrument.quoteSymbol] = {
                                id: instrument.instrumentId,
                                inverted: false
                            };
                            store.instrumentPairs[instrument.quoteSymbol + '/' + instrument.baseSymbol] = {
                                id: instrument.instrumentId,
                                inverted: true
                            };
                            if (instrument.instrumentId === 1)
                            {
                                store.frontToken = instrument.baseSymbol;
                                store.balanceToken = instrument.baseSymbol;
                                store.backToken = instrument.quoteSymbol;
                            }
                        }

                        console.log(instrument);
                        store.instrumentTokens[instrument.baseSymbol] = true;
                        store.instrumentTokens[instrument.quoteSymbol] = true;
                    }
                    mdservices.startChartData();
                }
                else
                {
                    store.instrumentStatus = 'Error! No instruments loaded';
                    console.log(store.instrumentStatus);
                }
                console.log('tokenDPs', store.tokenDPs);
                console.log('instrumentTokens', store.instrumentTokens);
                console.log('instrumentAddresses', store.tokenAddresses);
                console.log('instrumentDict', store.instrumentDict);
                console.log('instrumentPairs', store.instrumentPairs);
            }
        }
        catch (error)
        {
            store.instrumentStatus = 'Error! Could not load instruments: ' + error
            console.log(store.instrumentStatus);
        }
    }
});

