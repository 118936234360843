import {reactive} from 'vue'
import SHA256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import {providers} from 'ethers';

import {wsloaders, wsstore} from './Websocket';
import {mdservices} from './MarketData';
import domains from '../assets/domains.json';
import {getTokenAddressBalance, getTokenBalance, pollWallets} from "../lib/Wallet";
import BigNumber from "bignumber.js";
import {getRestBalance} from "../lib/RestApiClient";

const APP_NAME = 'Alice';
const APP_VERSION = '2.0.0';

const SEPOLIA_ETHERSCAN = 'https://sepolia.etherscan.io/tx/';
const MAINNET_ETHERSCAN = 'https://etherscan.io/tx/';

export const store = reactive({
  appname: APP_NAME,
  version: APP_VERSION,
  navType: 'boxed',
  windowWidth: window.innerWidth,
  useNarrowLayout: false,
  // backend info
  backendInfo: {},
  backendEnv: 'dev', // see domains.json
  pollFreq: 500, // see domains.json
  chainToUse: 'ETHEREUM', // see domains.json
  apiUrl: "filledInAutomatically", // see domains.json
  wssUrl: "filledInAutomatically", // see domains.json
  restApiUrl: "filledInAutomatically", // see domains.json
  // instruments
  instrumentStatus: '',
  instrumentList: [],
  instrumentDict: {},
  instrumentTokens: {},
  instrumentPairs: {},
  // liquidity balance
  liquidityBalances: {},
  // market data
  mdStatus: '',
  mdStarted: false,
  // charts
  availableIntervals: ["Hour", "Day", "Week", "Month"],
  availableIntervalsNarrow: ["Hour", "Day", "Week"],
  intervalLookups: {
    "Hour": '1m',
    "Day": '15m',
    "Week": '3h',
    "Month": '1d',
    "Year": '1w'
  },
  chartInterval: '3h',
  chartStatus: '',
  chartSymbol: '',
  chartTokens: [],
  chartData: [],        // in chartiq format
  chartRefreshTimer: null,
  chartNeedsRebuild: false,
  // tokens
  tokenStatus: '...',
  tokenList: [],
  tokenDict: {},
  tokenIDs: {},
  tokenDPs: {},
  // selected tokens
  leverage: 1,
  maxLeverage: 1,
  leveragePositions: [],
  frontToken: '',
  frontAmount: new BigNumber(1),
  balanceToken: '',
  balanceTokenBalance: new BigNumber(0),
  balanceTokenAllowance: new BigNumber(0),
  frontBalance: new BigNumber(0),
  frontDtwBalance: new BigNumber(0),
  frontAllowance: new BigNumber(0),
  backToken: '',
  backBalance: new BigNumber(0),
  backDtwBalance: new BigNumber(0),
  sellAmount: new BigNumber(0.01),
  buyAmount: new BigNumber(0),
  limitAmount: new BigNumber(0),
  rate: '1',
  rateLastUpdate: 0,
  displayRate: new BigNumber(1),
  isLimitOrder: true,
  slippageTolerance: "0.50",
  // SIWE
  domainForSIWE: window.location.host,
  methodForSIWE: 'https://',
  // smart contracts
  smartContracts: {},
  tokenAddresses: {},
  // wallets
  web3Onboard: null,
  onboardPoller: null,
  onboardPollCount: 0,
  dataProvider: null,
  walletProvider: null,
  pausePolling: false,
  walletIsConnected: false,
  clientID: null,
  isWhitelisted: false,
  ethscanPrefix: MAINNET_ETHERSCAN,
  lastPlacedOrder: null,
  restBalances: [],
  // METHODS
  getWalletProvider(wallet: any)
  {
    return new providers.Web3Provider(wallet.provider);
  },
  getDataProvider(wallet: any)
  {
    if (store.dataProvider == null)
    {
      if (store.backendEnv == "dev")
      {
        return new providers.JsonRpcProvider(store.getChainInfo().rpcUrl);
      }
      else
      {
        return this.getWalletProvider(wallet);
      }
    }
    else
    {
      return store.dataProvider;
    }
  },
  getChainInfo()
  {
    for (let c of (store.backendInfo as any).supportedChains)
    {
      if (c.label == store.chainToUse)
      {
        return c;
      }
    }
  },
  findInstrument()
  {
    const nonLevInstrumentId = store.instrumentPairs[store.frontToken + "/" + store.backToken]?.id;

    const levInstrumentId = store.instrumentPairs["lev_" + store.frontToken + "/" + store.backToken]?.id;

    if (levInstrumentId && store.frontToken.startsWith("USDC"))
    {
      store.maxLeverage = store.instrumentDict["lev_" + levInstrumentId].maxLeverage;
    }
    else
    {
      store.maxLeverage = 1;
    }

    if (nonLevInstrumentId)
    {
      wsloaders.subscribePrices(nonLevInstrumentId);
      console.log('findInstrument: OK', nonLevInstrumentId);
    }
    else
    {
      wsloaders.unsubscribePrices(wsstore.subscribedInstrument);
      console.error('findInstrument: NOT FOUND', store.frontToken, store.backToken);
    }
    store.chartNeedsRebuild = true;
    mdservices.loadChartData([store.frontToken, store.backToken]);
  },
  invertPair()
  {
    console.log("INVERT: front ", store.sellAmount, " back ", store.buyAmount)
    let token: string = store.frontToken;
    store.frontToken = store.backToken;
    store.backToken = token;
    let value = store.sellAmount;

    store.sellAmount = store.buyAmount;
    store.buyAmount = value;

    // reset allowance as we dont store back allowance
    store.frontAllowance = new BigNumber(0);

    let balance = store.frontBalance;
    store.frontBalance = store.backBalance;
    store.backBalance = balance;

    let balanceDtw = store.frontDtwBalance;
    store.frontDtwBalance = store.backDtwBalance;
    store.backDtwBalance = balanceDtw;

    store.rate = new BigNumber(1).div(store.rate).toFixed(9);
    store.findInstrument();
    store.chartNeedsRebuild = true;

    pollWallets();
  },
  updatePriceFromWebsocket(instrumentID: string, price: BigNumber)
  {
    if (instrumentID == wsstore.subscribedInstrument)
    {
      const shouldInvertPrice = store.instrumentDict[instrumentID].baseSymbol === store.backToken && store.instrumentDict[instrumentID].quoteSymbol === store.frontToken;
      const midprice: BigNumber = shouldInvertPrice ? new BigNumber(1).div(price) : price;
      store.rate = midprice.toString();
      store.rateLastUpdate = Date.now();
      store.displayRate = midprice;
      this.calculateBuyAmount();
    }
    else
    {
      console.log('received price for wrong instrument', wsstore.subscribedInstrument, instrumentID);
    }
  },
  setSellAmount(amount: BigNumber)
  {
    store.sellAmount = amount;
    this.calculateBuyAmount();
  },
  calculateBuyAmount()
  {
    store.buyAmount = store.sellAmount.multipliedBy(new BigNumber(store.rate));
  },
  setSlippageTolerance(amount: string)
  {
    store.slippageTolerance = amount;
  },
  async storeRestBalances(orderSource: string)
  {
    if (store.restApiUrl)
    {
      console.log("Storing rest balances")
      var response = await getRestBalance(orderSource);
      var json = await response.json();
      store.restBalances = json.balances;
      console.log("rest bal= ", store.restBalances)
    }
  }
});

export function resolveEnv()
{
  function digestMessage(nonce, message)
  {
    const hashDigest = SHA256(nonce + message);
    return Base64.stringify(hashDigest);
  }

  const host: string = location.host.split(':')[0];
  const hash = digestMessage(domains.nonce, host);
  if (hash in domains.data)
  {
    store.backendEnv = domains.data[hash].prodEnv;
    store.chainToUse = domains.data[hash].chain;
    store.pollFreq = 2500;
    if (store.backendEnv == 'demo')
    {
      store.ethscanPrefix = SEPOLIA_ETHERSCAN; // this will later be overidden by data from /info ep
    }
  }
}


